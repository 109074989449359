<template>
	<div class="login-container">
		<div class="loginFormBox bbox">
			<div class="bannerBox"></div>
			<div class="loginForm">
				<el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" autocomplete="on"
					label-position="left">
					<div style="text-align: center">
						<el-image :src="logoImg" style="width: 112px; height: 103px"></el-image>
					</div>
					<el-form-item prop="username" style="margin-top: 50px">
						<div class="bbox ycenter">
							<el-image :src="iconzhanghao" style="width: 18px; height: 18px"></el-image>
							<el-input ref="username" v-model="loginForm.username" placeholder="请输入账号" name="username"
								type="text" tabindex="1" autocomplete="off" />
						</div>
					</el-form-item>
					<el-tooltip v-model="capsTooltip" content="Caps lock is On" placement="right" manual>
						<el-form-item prop="password">
							<div class="bbox ycenter">
								<el-image :src="iconmima" style="width: 18px; height: 18px"></el-image>
								<el-input :key="passwordType" ref="password" v-model="loginForm.password"
									:type="passwordType" placeholder="请输入密码" name="password" tabindex="2"
									autocomplete="off" @keyup.native="checkCapslock" @blur="capsTooltip = false"
									@keyup.enter.native="handleLogin" />
								<span class="show-pwd" @click="showPwd">
									<svg-icon :icon-class="
                      passwordType === 'password' ? 'eye' : 'eye-open'
                    " />
								</span>
							</div>
						</el-form-item>
					</el-tooltip>

					<el-button :loading="loading" type="primary" class="loginBtn" @click.native.prevent="handleLogin">登录
					</el-button>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	// import { validUsername } from '@/utils/validate'
	// import { getCookie } from '@/utils/auth'
	import {
		Message
	} from "element-ui";

	export default {
		name: "Login",
		data() {
			return {
				title: "后台管理系统",
				logoImg: require("../../assets/logo_1.png"),
				iconzhanghao: require("../../assets/zhanghao.png"),
				iconmima: require("../../assets/mima.png"),
				loginForm: {
					username: "",
					password: "",
				},
				loginRules: {
					username: [{
						required: true,
						trigger: "blur"
					}],
					password: [{
						required: true,
						trigger: "blur"
					}],
				},
				passwordType: "password",
				capsTooltip: false,
				loading: false,
				showDialog: false,
				redirect: undefined,
				otherQuery: {},
			};
		},
		watch: {
			$route: {
				handler: function(route) {
					const query = route.query;
					if (query) {
						this.redirect = query.redirect;
						this.otherQuery = this.getOtherQuery(query);
					}
				},
				immediate: true,
			},
		},
		created() {
			// window.addEventListener('storage', this.afterQRScan)
		},
		mounted() {
			if (this.loginForm.username === "") {
				this.$refs.username.focus();
			} else if (this.loginForm.password === "") {
				this.$refs.password.focus();
			}
		},
		destroyed() {
			// window.removeEventListener('storage', this.afterQRScan)
		},
		methods: {
			checkCapslock(e) {
				const {
					key
				} = e;
				this.capsTooltip = key && key.length === 1 && key >= "A" && key <= "Z";
			},
			showPwd() {
				if (this.passwordType === "password") {
					this.passwordType = "";
				} else {
					this.passwordType = "password";
				}
				this.$nextTick(() => {
					this.$refs.password.focus();
				});
			},
			handleLogin() {
				this.$refs.loginForm.validate((valid) => {
					if (valid) {
						this.loading = true;
						this.$store
							.dispatch("user/login", this.loginForm)
							.then((data) => {
								this.loading = false;
								console.log(data,'=====登录信息=====');
								//存本地
								localStorage.setItem('is_admin',data.is_admin);
								// '1:普通管理员;2:药师;3:药房10超级管理员',
								// this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
								this.$router.push({
									path: "/",
									query: this.otherQuery
								});
								this.loading = false;
							})
							.catch((err) => {
								console.log(err);
								// Message({
								//   message: err.message,
								//   type: "error",
								// });
								// this.loading = false;
							});
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			getOtherQuery(query) {
				return Object.keys(query).reduce((acc, cur) => {
					if (cur !== "redirect") {
						acc[cur] = query[cur];
					}
					return acc;
				}, {});
			},
		},
	};
</script>

<style lang="scss">
	/* 修复input 背景不协调 和光标变色 */
	/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

	$bg: #283443;
	$light_gray: #fff;
	$cursor: #fff;

	@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
		.login-container .el-input input {
			color: $cursor;
		}
	}

	/* reset element-ui css */
	.login-container {
		.el-input {
			display: inline-block;

			input {
				background: transparent;
				border: 0px;
				-webkit-appearance: none;
				border-radius: 0px;
				padding: 12px 5px 12px 15px;
				color: #333;

				&:-webkit-autofill {
					box-shadow: 0 0 0px 1000px $bg inset !important;
					-webkit-text-fill-color: $cursor !important;
				}
			}
		}

		.el-form-item {
			border-bottom: 1px solid #c8cacb;
			color: #454545;
			margin-bottom: 0;
			margin-top: 20px;
		}

		.vailcodeBox {
			.el-form-item__content {
				display: flex;
				width: 100%;
				position: relative;

				.valiCode {
					position: absolute;
					right: 5px;
					top: 50%;
					transform: translateY(-50%);
					width: 108px;
					height: 40px;
				}
			}
		}
	}
</style>

<style lang="scss" scoped>
	$bg: #2d3a4b;
	$dark_gray: #889aa4;
	$light_gray: #eee;

	.loginFormBox {
		width: 780px;
		height: 488px;
		margin: auto;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		.bannerBox {
			background-image: url("../../assets/login_banner.png");
			background-repeat: no-repeat;
			width: 400px;
			background-size: contain;
			border-radius: 8px 0 0 8px;
			box-shadow: 0 0 20px 8px rgba(0, 0, 0, 0.15);
		}

		.loginForm {
			width: 380px;
			background: #fff;
			padding: 55px 60px 0;
		}
	}

	.login-container {
		min-height: 100%;
		height: 100%;
		width: 100%;
		background: url("../../assets/login_bg.png") no-repeat;
		background-color: #f1f7fc;
		background-size: cover;
		overflow: hidden;

		.login-form {
			position: relative;
			max-width: 100%;
			margin: 0 auto;
			overflow: hidden;
		}

		.tips {
			font-size: 14px;
			margin-bottom: 10px;

			span {
				&:first-of-type {
					margin-right: 16px;
				}
			}
		}

		.svg-container {
			vertical-align: middle;
			display: inline-block;
		}

		.title-container {
			position: relative;

			.title {
				font-size: 26px;
				color: $light_gray;
				margin: 0px auto 40px auto;
				text-align: center;
				font-weight: bold;
			}
		}

		.show-pwd {
			position: absolute;
			right: 10px;
			top: 7px;
			font-size: 16px;
			color: $dark_gray;
			cursor: pointer;
			user-select: none;
		}

		.thirdparty-button {
			position: absolute;
			right: 0;
			bottom: 6px;
		}

		.loginBtn {
			background: linear-gradient(90deg, #0091ff 0%, #00aeff 100%);
			color: #fff;
			width: 100%;
			border: none;
			font-size: 18px;
			padding: 12px 0;
			margin-top: 40px;
		}

		@media only screen and (max-width: 470px) {
			.thirdparty-button {
				display: none;
			}
		}
	}
</style>
